import { navigate } from "gatsby";
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TreeMenu from 'react-simple-tree-menu';
import '../../node_modules/react-simple-tree-menu/dist/main.css';
import Header from '../components/Header/Header';
import '../components/layout.css';
import SEO from '../components/seo';
import '../static/landing.css';
import '../static/ubications.css';

const Ubications = ({ pageContext }) => {
  const [t, i18n] = useTranslation();
  const { lang = "es", slugPartBoatRent, special, places } = pageContext;

  let arrayOfChilds = [];
  let treeDataAux = {};
  JSON.parse(places).forEach((place) => {
    if(!place.ancestry) {
      const temporaryObject = {[place.name[lang]] : {
        label: place.name[lang],
        index: place.id, 
        slug: place.slug[lang],
        nodes: {},
      }};
      Object.assign(treeDataAux, temporaryObject);}
      else{
        const ancestries = place.ancestry.split('/');
        if(arrayOfChilds[ancestries[ancestries.length - 1]] === undefined){
          arrayOfChilds[ancestries[ancestries.length - 1]] = [];
        }
        arrayOfChilds[ancestries[ancestries.length - 1]].push({[place.name[lang]] : {
          label: place.name[lang],
          index: place.id,
          slug: place.slug[lang],
          nodes: {},
        }});
      }
  });

  const createChilds = (objectToLoop) => {
    Object.keys(objectToLoop).forEach((firstData) => {
      if(arrayOfChilds[objectToLoop[firstData].index]){
        arrayOfChilds[objectToLoop[firstData].index].forEach(element => {
          Object.assign(objectToLoop[firstData].nodes, element);
          createChilds(objectToLoop[firstData].nodes);
        })
      }
    });
  }
  
  let treeData = treeDataAux;
  createChilds(treeData);

  return (
    <>
      <SEO title="Ubications" description={pageContext.description} url={pageContext.url} />
      <Header special={special} page={"search"}/>
      <div style={{marginTop: '64px'}}>
        <TreeMenu
          data={treeData}
          onClickItem={({ slug }) => {
            navigate(`/${lang}/${slugPartBoatRent[lang]}/${slug}`);
          }}
          debounceTime={125}
          disableKeyboard={false}
          hasSearch
          resetOpenNodesOnDataUpdate={false}
        />
      </div>
    </>
  );
};

Ubications.propTypes = {
  suggestedDestinations: PropTypes.array,
  customPost: PropTypes.string,
  styles: PropTypes.array,
};
Ubications.defaultProps = {
  suggestedDestinations: null,
  customPost: '<div></div>',
  styles: [],
};

export default Ubications;
